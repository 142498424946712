/* @import url('https://fonts.googleapis.com/css2?family=Cutive&family=Gowun+Batang:wght@400;700&display=swap'); */

@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap');

body {
  margin: 0;
  font-family: 'DM Sans' !important;
  font-size: larger;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.customfont {
  font-family: 'DM Sans', sans-serif !important;
}

h1,
h2,
h3 {
  font-family: 'DM Sans', sans-serif !important;
  font-weight: bolder;
}


table {
  border-radius: 32px !important;
  border: 2px solid #6a355b;
  width: 100%;
  overflow: scroll;
}

thead,
th {
  border: 1px solid #6a355b;
  font-weight: bold;
  /* background-color: rgb(231, 231, 231); */
  padding: 8px;
  font-size: 16px;
  /* font-family: 'Geologica' !important; */
}

/* tr:nth-child(odd) {
  background-color: #f1efef;
  } */

td {
  border: 1px solid #6a355b;
  font-family: 'DM Sans' !important;
  font-size: 14px !important;
  padding: 8px !important;
  text-align: center;
}

.InovuaReactDataGrid {
  min-height: 55vh !important;
  height: 100% !important;
}